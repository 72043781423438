// extracted by mini-css-extract-plugin
export var background_images = "Banner-module__background_images___1IWSF";
export var banner = "Banner-module__banner___quAWu";
export var banner__content = "Banner-module__banner__content___AQI-T";
export var banner__image = "Banner-module__banner__image___yc8KA";
export var banner__subtitle = "Banner-module__banner__subtitle___mMiaF";
export var banner__title = "Banner-module__banner__title___13siO";
export var body_background = "#f8f9fa";
export var card = "Banner-module__card___2s+LD";
export var chip = "Banner-module__chip___ig1vF";
export var container = "Banner-module__container___-Hf0w";
export var content = "Banner-module__content___+bXlG";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "Banner-module__full_img___Sy-jL";
export var full_imgSmall = "Banner-module__full_img--small___eRlIl";
export var gray_21 = "Banner-module__gray_21___SOkZ3";
export var image_container = "Banner-module__image_container___8UfPC";
export var image_item = "Banner-module__image_item___6CTh5";
export var lg = "1200px";
export var logo = "Banner-module__logo___24-Hm";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "Banner-module__primary___BOdhz";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "Banner-module__title___yhGDO";
export var white = "#fff";
export var white_button = "Banner-module__white_button___fA54i";
export var xl = "1536px";
export var xxl = "2500px";