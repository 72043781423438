import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Stack } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './Banner.module.scss';

const Banner = ({ intl: { formatMessage } }) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className={styles.banner}>
      <Stack className={styles.banner__content} spacing={2}>
        <Typography variant="h2" className={styles.banner__title}>
          {formatMessage({ id: 'aboutUsTitle1' })}{' '}
          <span className={styles.primary}>
            {formatMessage({ id: 'aboutUsTitle2' })}
          </span>
        </Typography>
        <Typography variant="h6" className={styles.banner__subtitle}>
          {formatMessage({ id: 'aboutUsSubtitle' })}
        </Typography>
      </Stack>
      {isDesktop ? (
        <StaticImage
          src="../../../images/aboutUs/about_us_banner.webp"
          alt="Banner"
          className={styles.banner__image}
          layout="fullWidth"
          placeholder="blurred"
          loading="lazy"
          formats={["auto", "webp", "avif"]}
        />
      ) : (
        <StaticImage
          src="../../../images/aboutUs/about_us_banner_mobile.webp"
          alt="Banner"
          className={styles.banner__image}
          layout="fullWidth"
          placeholder="blurred"
          loading="lazy"

          formats={["auto", "webp", "avif"]}
        />
      )}
    </section>
  );
};

Banner.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Banner);
